/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W marcu 2024 roku rozpoczęły się prace nad kolejną częścią zadania inwestycyjnego pod nazwą „Rozbudowa drogi powiatowej 1507C Włóki – Jarużyn – Bydgoszcz na odcinku Włóki- Gądecz”. To już trzecia część tej inwestycji, którą realizuje Transpol Lider. Obecnie rozbudową został objęty odcinek od km 2+675 do 3+462,30."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych zostały wykonane między innymi następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbiórka istniejącej konstrukcji jezdni o nawierzchni asfaltowej, zjazdów z kostki, kolidujących ogrodzeń"), "\n", React.createElement(_components.li, null, "wykonanie nowej jezdni o nawierzchni z betonu asfaltowego,"), "\n", React.createElement(_components.li, null, "budowa zatoki autobusowej o nawierzchni betonowej, zjazdów z betonu asfaltowego oraz kostki betonowej"), "\n", React.createElement(_components.li, null, "budowa poboczy gruntowych, chodników, drogi rowerowej oraz ciągu pieszo-rowerowego o nawierzchni bitumicznej"), "\n", React.createElement(_components.li, null, "wykonanie wybrukowań przy wskazanych skrzyżowaniach"), "\n", React.createElement(_components.li, null, "budowa kanalizacji deszczowej, przebudowa sieci wod.-kan. oraz sieci energetycznych i telekomunikacyjnych, a także oświetlenia"), "\n", React.createElement(_components.li, null, "wykonanie nasadzeń zastępczych, zieleni w postaci trawników,"), "\n", React.createElement(_components.li, null, "wykonanie na przejściach dla pieszych oświetlenia ulicznego LED oraz znaków informacyjnych na fluorescencyjnym tle oraz płytki wskaźnikowe/ostrzegawcze"), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany był na 3 miesiące. Inwestycja planowo została oddana do użytku w lipcu 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
